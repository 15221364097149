"use client";

import { SIGN_IN_LINK } from "@/app/constants";
import { usePathname } from "@/navigation";
import Link from "next/link";

function link(redirectPath) {
  return `${SIGN_IN_LINK}?redirect_to=${redirectPath}`;
}

export function SignInLink({ children, ...rest }) {
  return (
    <Link href={link(usePathname())} {...rest}>
      {children}
    </Link>
  );
}

export function SignInNavLink() {
  const pathname = usePathname();
  return (
    <li>
      <Link href={link(usePathname())} className="text-primary">
        Log In
      </Link>
    </li>
  );
}
